<template>
  <div>
    <topbar />
    <main-table />
  </div>
</template>

<script>
import MainTable from '../components/browserProfiles/BrowserProfilesMainTable.vue';
import Topbar from '../components/browserProfiles/BrowserProfilesTopbar.vue';

export default {
  name: 'BrowserProfiles',

  components: {
    MainTable,
    Topbar,
  },

  created() {
    this.$store.dispatch('browserProfiles/loadBrowserProfiles');
  },

  beforeDestroy() {
    this.$store.dispatch('browserProfiles/clearSelected');
  },
};
</script>
