<template>
  <div class="d-flex align-center justify-space-between">
    <!-- ЛЕВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <!-- УДАЛИТЬ -->
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            :disabled="$_.isEmpty(browserProfiles.selected)"
            class="mr-2"
            :loading="loading.deleteButton"
            v-on="on"
            @click="showDialog = true"
          >
            <v-icon size="18">
              mdi-restore
            </v-icon>
          </v-btn>
        </template>
        <span>Восстановить</span>
      </v-tooltip>
    </div>
    <!-- ПРАВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <!-- ОБНОВИТЬ СПИСОК -->
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            class="ml-2 mr-4"
            color="primary"
            icon
            :disabled="loading.mainTable"
            v-on="on"
            @click="$store.dispatch('browserProfiles/loadCurrentPage')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
      <!-- ПОИСК ПО ДАТЕ -->
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        close-delay="mr-4"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchParams.date"
            clearable
            label="по дате"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            class="mr-4"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="searchParams.date"
          class="mt-3 mb-6"
          range
          @input="searchDate();"
        />
      </v-menu>
      <!-- ПОИСК ПО ТИПУ -->
      <v-select
        v-model="searchParams.type"
        :items="['archived', 'deleted']"
        label="Тип"
        class="mr-3"
        clearable
        style="width: 150px"
        @input="search"
      />
      <!-- ПОИСК ПО ИМЕНИ -->
      <v-text-field
        :value="topbarSearch.searchInputName"
        label="Поиск по названию"
        dense
        solo
        single-line
        hide-details
        clearable
        class="mr-3"
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="searchName"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>

      <!-- ПОИСК ПО ЮЗЕРУ -->
      <v-text-field
        :value="topbarSearch.searchInputUser"
        label="Поиск по email"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="searchUser"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-account
          </v-icon>
        </template>
      </v-text-field>
    </div>
    <BrowserProfileRestoreDialog
      v-model="showDialog"
      :loader="loader"
      @restore="restoreSelected"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import BrowserProfileRestoreDialog
  from '@/components/browserProfiles/BrowserProfileRestoreDialog.vue';

export default {
  name: 'BrowserProfilesTopbar',
  components: { BrowserProfileRestoreDialog },
  data() {
    return {
      env: 'prod',
      showDialog: false,
      loader: false,
      dateFrom: '',
      date: '',
      menu: '',
      type: '',
    };
  },
  watch: {
    searchParams: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.topbarSearch.searchInputUser = 'admin@dolphin.ru.com';
  //     this.searchUser('admin@dolphin.ru.com');
  //   }, 100);
  // },
  computed: {
    ...mapGetters({
      loading: 'browserProfiles/loading',
      profile: 'main/profile',
      topbarSearch: 'browserProfiles/topbarSearch',
      pagination: 'browserProfiles/pagination',
    }),
    ...mapFields('browserProfiles', ['browserProfiles', 'searchParams']),
  },
  created() {
    this.closeDateRange = this.$_.debounce(this.closeDateRange, 1500);
    this.searchDate = this.$_.debounce(this.searchDate, 300);
    this.searchName = this.$_.debounce(this.searchName, 300);
    this.searchByEnvironment = this.$_.debounce(this.searchByEnvironment, 300);
    this.searchUser = this.$_.debounce(this.searchUser, 300);
    this.search = this.$_.debounce(this.search, 300);
  },

  methods: {
    search() {
      this.$store.dispatch('browserProfiles/loadBrowserProfiles');
    },
    searchByEnvironment() {
      this.searchParams.env = this.env ? this.env : 'prod';
      this.$store.dispatch('browserProfiles/loadBrowserProfiles');
    },
    async restoreSelected() {
      this.loader = true;
      const idsArray = this.browserProfiles.selected.map((obj) => obj.id);
      const data = {
        profileIds: idsArray,
      };

      try {
        const response = await this.api.patch('/admin/browser_profiles/restore', data);

        if (response.status === 200) {
          this.browserProfileRestoringProfilesDialog = false;
          await this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Профили восстановлены',
          });
          await this.$store.dispatch('browserProfiles/loadBrowserProfiles', {
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            searchUser: this.topbarSearch.searchInputUser,
            searchName: this.topbarSearch.searchInputName,
            env: this.env,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader = false;
        this.showDialog = false;
      }
    },

    searchName(name) {
      this.topbarSearch.searchInputName = name;
      if (name === '') {
        this.searchParams.searchUser = null;
        this.searchParams.searchName = null;
        return;
      }
      if (name === this.topbarSearch.searchInputName) {
        this.searchParams.searchName = this.topbarSearch.searchInputName;
        this.searchParams.searchUser = this.topbarSearch.searchInputUser;
        this.$store.dispatch('browserProfiles/loadBrowserProfiles');
      }
    },
    searchDate() {
      this.$store.dispatch('browserProfiles/loadBrowserProfiles');
      this.closeDateRange();
    },
    closeDateRange() {
      this.menu = false;
    },
    searchUser(name) {
      const emailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,30})+$/;
      if (emailRegexp.test(name)) {
        this.topbarSearch.searchInputUser = name;
        if (name === this.topbarSearch.searchInputUser) {
          this.searchParams.searchName = this.topbarSearch.searchInputName;
          this.searchParams.searchUser = this.topbarSearch.searchInputUser;
          this.$store.dispatch('browserProfiles/loadBrowserProfiles');
        }
      } else {
        this.searchParams.searchName = '';
        this.searchParams.searchUser = '';
        this.$store.dispatch('browserProfiles/loadBrowserProfiles');
      }
    },
  },
};
</script>
