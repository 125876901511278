<template>
  <v-dialog
    v-model="localState"
    width="500"
    color="primary"
  >
    <v-card>
      <v-card-title class="text-h5 blue">
        Восстановить
      </v-card-title>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="primary"
          text
          @click="localState = false"
        >
          Отменить
        </v-btn>

        <v-btn
          color="primary"
          text
          :loading="loader"
          @click="$emit('restore')"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
<script>
export default {
  props: ['value', 'loader'],
  computed: {
    localState: {
      get() {
        return this.value;
      },
      set(localState) {
        this.$emit('input', localState);
      },
    },
  },
};
</script>
